@import '../../../variables';

.lighting-dialog {
  .close-dialog {
    position: absolute;
    top: 20px;
    right: 20px;

    &:hover {
      color: $primary-light;
      cursor: pointer;
    }
  }

  .lighting-dialog-content {
    display: flex;
    flex-direction: row;
    padding-left: 24px;
  }
}
