@import '../../../variables';

.keyboarddefinitionform-wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: white;
  flex: 1 1;

  .message {
    margin: 0 auto;
    padding: $space-l 0;
    font-size: 1rem;
    overflow-wrap: anywhere;
    &.message-small {
      font-size: 0.87rem;
    }
  }

  .upload-form {
    position: relative;
    width: 320px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    padding: 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 auto;

    &.area-small {
      width: 200px;
      .drop-target {
        width: 180px;
        height: 180px;
      }
    }

    .place-holder {
      div {
        text-align: center;
      }
      .import-file {
        input {
          display: none;
        }
      }
    }

    .drop-target {
      width: 300px;
      height: 280px;
      border-radius: 10px;
      border: 1px dashed rgba(0, 0, 0, 0.2);
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      color: rgba(0, 0, 0, 0.2);
      font-size: 1rem;
      font-weight: normal;

      &-active {
        border: 2px dashed rgba(0, 0, 0, 0.2);
        font-weight: bold;
      }
    }
    .keyboarddefinitionform-loading {
      position: absolute;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      height: 100%;
      width: 100%;
      background-color: rgba(255, 255, 255, 0.9);
    }
  }

  .validation-errors {
    width: 100%;
    max-width: 853px;
    margin: $space-m auto;
    #other-possibilities {
      .MuiAccordionDetails-root {
        flex-direction: column;
      }
    }
  }
  .invalid-item {
    margin-top: $space-m;
    overflow-wrap: anywhere;
  }
}
