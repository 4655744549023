@import '../../../variables';

.edit-definition-wrapper {
  position: fixed;
  width: 100%;
  margin-top: calc(var(--key-height));
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.edit-definition-container {
  width: 100%;
  height: calc(100vh - var(--key-height) - 26px);
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.edit-definition-card {
  width: 100%;
  max-width: 960px;
  padding-top: 16px;
  padding-bottom: 16px;
}

.edit-definition-alert {
  margin: 0 32px 32px 32px;
}

.edit-keyboard-header {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.edit-keyboard-tabs {
  margin-bottom: 16px;
}

.edit-keyboard-stepper {
  margin-top: $space-l;
  margin-bottom: $space-l !important;
}
