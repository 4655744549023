@import '../../../variables';

.keyboarddefinitionform-wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: white;
  flex: 1 1;
  width: 100%;

  .initializing {
    position: relative;
    width: 320px;
    height: 320px;
    padding: 8px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
  }

  .drop-target {
    width: 300px;
    height: 280px;
    border-radius: 10px;
    border: 1px dashed rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    color: rgba(0, 0, 0, 0.2);
    font-size: 1rem;
    font-weight: normal;

    &-active {
      border: 2px dashed rgba(0, 0, 0, 0.2);
      font-weight: bold;
    }
  }
}
