@import 'src/variables';

.catalog-firmware {
  &-wrapper {
    position: relative;
    width: 100%;
    max-width: 960px;
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 32px;
  }

  &-panel {
    width: 100%;
    //border: 16px solid #fff;
    padding: 16px;
    background-color: #fff;

    &:last-child {
      padding-bottom: 0;
    }
  }

  &-total-download-count {
    width: 100%;
    margin-bottom: $space-m;
  }

  &-card {
    width: 100%;
    margin-bottom: 16px;

    &-caption {
      word-break: break-all;
    }

    &-buttons {
      justify-content: flex-end;
    }
  }

  &-nothing {
    background-color: #fff;
    width: 100%;
    padding: 16px;
  }

  &-device-info-item {
    display: inline-block;
  }
}
