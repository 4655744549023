.hero-content {
  background-color: white;
  padding: 64px 0 48px;
}

.hero-buttons {
  margin-top: 32px;
}

.card-grid {
  padding-top: 64px;
  padding-bottom: 64px;
}

.card {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.card-media {
  height: 0;
  padding-top: 56.25%; // 16.9
}

.card-content {
  flex-grow: 1;
}

.feature-image {
  width: 100%;
  height: 150px;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
}
