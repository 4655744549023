@import '../../../variables';

.keymaplist-root {
  .keymaplist {
    padding: 0 $space-l $space-m $space-l;
  }
  .keymaplist-popover {
    width: 400px;
    max-height: 400px;
    display: flex;
    flex-direction: column;
    .keymaplist-header {
      display: flex;
      flex-direction: row;
      align-items: center;
      border-bottom: 1px solid rgba(0, 0, 0, 0.2);
      .keymaplist-header-buttons {
        margin-left: auto;
      }
    }
    .keymaplist-content {
      display: flex;
      flex-direction: column;
      overflow-y: scroll;
      overflow-x: hidden;
      -ms-overflow-style: none; /* IE, Edge */
      scrollbar-width: none; /* Firefox */
      &::-webkit-scrollbar {
        /* Chrome, Safari  */
        display: none;
      }
      .no-saved-keymap {
        padding-bottom: $space-l;
        .keymaplist-warning {
          color: $warning;
        }
      }
      .my-keymaplist-header {
        display: flex;
        flex-direction: row;
        align-items: center;
        &-shared {
          margin-right: 8px;
        }
      }
      .MuiTab-root {
        min-width: 120px;
        width: 120px;
      }
      .MuiListItemText-primary {
        overflow-x: scroll;
        white-space: nowrap;
        -ms-overflow-style: none; /* IE, Edge */
        scrollbar-width: none; /* Firefox */
        &::-webkit-scrollbar {
          /* Chrome, Safari  */
          display: none;
        }
      }
      .MuiListItemText-secondary {
        overflow-wrap: anywhere;
        overflow-y: scroll;
        max-height: 40px;
        -ms-overflow-style: none; /* IE, Edge */
        scrollbar-width: none; /* Firefox */
        &::-webkit-scrollbar {
          /* Chrome, Safari  */
          display: none;
        }
      }
      .MuiListItemIcon-root {
        min-width: 36px;
      }
    }

    .request-signin {
      padding: $space-l;
      display: flex;
      flex-direction: column;

      .request-signin-message {
        color: $warning;
      }
      .request-signin-actions {
        display: flex;
        justify-content: center;
        padding-top: $space-l;
      }
    }
  }
}
