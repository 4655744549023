@import '../../../variables';

.catalog-keyboard-header {
  color: white;
  width: 100%;
  max-width: 960px;
  margin: 8px 0px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  flex-wrap: wrap;

  &-wrapper {
    display: flex;
    flex-direction: row;
    width: 100%;
    padding: 16px 16px 16px 8px;
    background-color: $primary;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
  }

  &-title {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;

    & a {
      color: white;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
      }
    }

    & h1 {
      font-size: 28px;
      font-weight: bold;
    }
    & h6 {
      font-size: 0.9rem;
    }
    @include mq(lg) {
      & h1 {
        font-size: 24px;
        font-weight: bold;
      }
      & h6 {
        font-size: 0.8rem;
      }
    }
  }

  &-links {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: auto;

    &-github {
      margin-left: 8px;
      margin-right: 16px;
    }

    &-home {
      margin-left: 16px;
    }

    &-stores {
      margin-left: 16px;
    }
    @include mq(lg) {
      &-github {
        margin-left: 8px;
        div {
          width: 32px;
          height: 32px;
        }
      }

      &-home {
        width: 32px;
        height: 32px;
        margin-left: 8px;
      }

      &-stores {
        margin-left: 8px;
        button {
          width: 32px;
          height: 32px;
          padding: 0;
        }
      }
    }
  }
}

@include mq(sm) {
  .catalog-keyboard-header {
    margin-top: 0;
    margin-bottom: 8px;
  }
}
