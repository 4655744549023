@import '../../../variables';

.keydiff-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
  height: 78px;
  z-index: 3;
  .diff {
    display: flex;
    flex-direction: row;
    justify-content: center;
    margin-bottom: $space-m;
    .test-matrix-message {
      z-index: 2;
      color: white;
      display: flex;
      flex-direction: column;
      justify-content: center;
      h3 {
        margin: 0;
      }
    }
    .close-test-matrix {
      display: flex;
      z-index: 2;
      margin-left: auto;
      margin-top: auto;
      button {
        color: white;
      }
    }
  }
  .label-lang {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: $space-l;
  }

  .label-lang,
  .balancer {
    min-width: 80px;
  }
}

.keyboards-wrapper {
  display: flex;
  flex-direction: row;

  .layer-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    min-width: 80px;
    padding-left: $space-l;
    z-index: 2;

    .MuiPagination-ul {
      flex-direction: column;
    }
  }

  .keyboards {
    display: flex;
    flex-direction: row;
    justify-content: center;
    position: relative;
    z-index: 2;
    .keyboard-root {
      display: flex;
      justify-content: center;
      align-items: center;
      border-left: 5px solid #ececec;
      border-right: 5px solid #ececec;
      border-top: 1px solid #ececec;
      border-bottom: 8px solid #f3f3f3;
      border-radius: 8px;
      background-color: #fcfcfc;
      -webkit-filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.2));
      -moz-filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.2));
      -ms-filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.2));
      filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.2));
      .keyboard-frame {
        width: 100%;
        position: relative;
      }
    }
    .app-info {
      position: absolute;
      right: $space-l;
      bottom: $space-s;
      color: $primary-pale;
    }
  }
}
