@import 'src/variables';

.catalog-introduction {
  &-wrapper {
    position: relative;
    width: 100%;
    max-width: 960px;
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 32px;
  }

  @include mq(sm) {
    &-container {
      padding-top: 0;
    }
  }

  &-content {
    width: 100%;
  }

  &-column {
    width: 100%;
  }

  &-image {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 18px 0;

    .image-gallery-slides {
      display: flex;
      width: 438px;
      align-items: center;
    }
    @include mq(lg) {
      .image-gallery-slides {
        width: 100%;
      }
    }
    .image-gallery-thumbnail {
      width: 108px;
      border: 2px solid transparent;

      &.active,
      &:focus {
        outline: none;
        border: 2px solid $primary;
      }
    }

    @include mq(lg) {
      .image-gallery-thumbnail {
        width: 80px;
      }
    }

    &-nothing {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      color: $color-gray-600;
      width: 438px;
      height: 328px;
      background-color: $color-gray-300;
    }
  }
  @include mq(sm) {
    &-image {
      padding: 0;
      &-nothing {
        width: 100%;
        height: 300px;
      }
    }
  }

  &-section {
    margin: 8px 16px 24px 8px;

    & h2 {
      font-size: 22px;
      font-weight: bold;
      margin-bottom: 16px;
    }
  }

  &-chip-container {
    margin-bottom: 16px;

    & div {
      margin-right: 8px;
      margin-bottom: 8px;
    }
  }

  &-stores {
    display: flex;
    flex-direction: row;
  }

  &-store {
    display: inline-block;
    margin-right: 16px !important;
  }

  &-description-tab {
    margin-bottom: 8px;
  }

  &-same-author-keyboard {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin-bottom: 4px;
    border: 1px solid #fff;
    cursor: pointer;

    &:hover {
      border: 1px solid $color-gray-300;
    }

    &-image {
      width: 100px;
      height: 75px;
      background-repeat: no-repeat;
      background-size: contain;
      background-position: center;
    }

    &-no-image {
      width: 100px;
      height: 75px;
      border: 1px dotted $color-gray-300;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      color: $color-gray-600;
      background-color: $color-gray-300;
      font-size: 0.8rem;
    }

    &-name {
      margin-left: 16px !important;
    }
  }
}
