@import 'src/variables';

.catalog-keymap {
  &-container {
    width: 100%;
    overflow-y: auto;
    overflow-x: clip;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 8px;

    &-wrapper {
      position: relative;
      width: 100%;
      z-index: 1;
      display: flex;
      flex-direction: column;
      align-items: center;
      background: white;
      margin-bottom: 32px;
    }
  }

  &-header {
    width: 100%;
    max-width: 960px;
    margin-bottom: 16px;

    &-row {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: flex-end;

      & h1 {
        font-size: 28px;
        font-weight: bold;
      }

      & h6 {
        margin-left: 16px;
      }
    }
  }

  &-wrapper {
    display: flex;
    flex-direction: column;
  }

  &-keyboards {
    display: flex;
    flex-direction: row;
    justify-content: center;
    position: relative;
    z-index: 2;
  }

  &-keyboard-root {
    display: flex;
    justify-content: center;
    align-items: center;
    border-left: 5px solid #ececec;
    border-right: 5px solid #ececec;
    border-top: 1px solid #ececec;
    border-bottom: 8px solid #f3f3f3;
    border-radius: 8px;
    background-color: #fcfcfc;
    -webkit-filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.2));
    -moz-filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.2));
    -ms-filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.2));
    filter: drop-shadow(0px 2px 2px rgba(0, 0, 0, 0.2));
  }

  &-keyboard-frame {
    width: 100%;
    position: relative;
  }

  &-option {
    &-container {
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      padding: $space-l $space-l 0 $space-l;
      z-index: 2;
    }

    &-side {
      display: flex;
      width: 100px;
    }

    &-side-left {
      display: flex;
      margin-right: auto;
      justify-content: flex-start;
    }

    &-side-right {
      display: flex;
      margin-left: auto;
      justify-content: flex-end;
    }

    &-lang {
      white-space: nowrap;
    }

    &-pdf {
    }
    &-menu {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      z-index: 3;
      div {
        margin-right: 8px;
      }
    }
  }

  &-layer-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-start;
    min-width: 88px;
  }

  &-content {
    width: 100%;
    max-width: 960px;
    margin-top: 16px;
  }

  &-column {
    width: 100%;
  }

  &-section {
    margin: 8px;

    & h2 {
      font-size: 22px;
      font-weight: bold;
      margin-bottom: 16px;
    }
  }
}
