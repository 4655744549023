@import '../../variables';

.documents {
  &-wrapper {
    display: flex;
    position: relative;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding-top: 72px;
    padding-bottom: 32px;
    justify-content: center;
  }

  &-container {
    max-width: 960px;

    & h3 {
      margin: 0 16px 24px 16px;
    }

    & h4 {
      margin-bottom: 8px;
    }

    & h5 {
      margin-bottom: 8px;
    }

    & section {
      margin: 0 16px 32px 16px;
    }

    & p {
      margin-bottom: 16px;

      & a {
        margin: auto 4px;
      }

      & code {
        background-color: $color-gray-200;
      }
    }
  }
}
