@import 'src/variables';

.footer {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  position: fixed;
  background-color: white;
  padding: $space-s $space-m;
  bottom: 0;
  left: 0;
  width: 100%;
  font-size: 0.8rem;
  box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.14);
  z-index: 1;

  a {
    color: $primary-light;
  }
  &-dev-team {
    text-align: start;

    &-years {
      display: inline-block;
      margin-right: 4px;
    }
  }
  &-contents {
    text-align: center;

    & .footer-content {
      display: inline-block;

      &:not(:first-child) {
        margin-left: 16px;
      }
    }
    &-mobile {
      display: none;
    }
  }
  .app-version {
    text-align: end;
    color: $color-gray-400;
  }

  @include mq(sm) {
    &-dev-team {
      &-years {
        display: none;
      }
    }
    &-contents {
      display: none;

      &-mobile {
        display: block;
        text-align: center;
      }
    }
  }
}
