@import '../../../variables';

.info-dialog {
  .close-dialog {
    position: absolute;
    top: 20px;
    right: 20px;

    &:hover {
      color: $primary-light;
      cursor: pointer;
    }
  }

  &-information-message {
    font-size: 90%;
    line-height: 0.95rem;
    color: $color-gray;
    margin-bottom: $space-m;
  }

  &-warning-message {
    font-size: 90%;
    line-height: 0.95rem;
    color: $error;
    margin-bottom: $space-m;
  }
}
