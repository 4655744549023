@import '../../_variables.scss';

.configure-root {
  display: flex;
  flex-direction: column;
  height: 100vh;
  background-color: $background-color-gray;
}

.message-box-wrapper {
  background-color: white;
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  .message-box {
    width: 600px;
    border: 1px solid lightgray;
    border-radius: 8px;
    padding: 16px;
    margin-top: 64px;
  }
}

.dragMask {
  position: absolute;
  background: rgba(0, 0, 0, 0.4);
  width: 100%;
  height: 100%;
  z-index: 1;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  &.fill-blank {
    position: relative;
    width: 100%;
    flex-grow: 1;
  }
}
