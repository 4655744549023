@import '../../../variables';

.catalog-search-wrapper {
  position: relative;
  width: 100%;
  max-width: 960px;
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
}

.catalog-search-container {
  width: 100%;
  margin-top: calc(var(--key-height));
  height: calc(100vh - var(--key-height));
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.catalog-search-condition-container {
  display: flex;
  flex-direction: column;
  margin: 16px;
}

.catalog-search-condition {
  margin-bottom: 16px;
}
.catalog-search-condition-xm {
  padding: 8px 16px;
  &-keyword {
    font-weight: 600;
    margin-right: 4px;
    margin-bottom: 4px;
  }
  .MuiChip-root {
    margin-right: 4px;
    margin-bottom: 4px;
    font-size: 0.7rem;
  }
}

.catalog-search-buttons {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  & button {
    margin-left: 16px;
  }
}

.catalog-search-result-container {
  display: flex;
  flex-direction: column;
  margin: 16px;
  margin-bottom: 48px;

  & a {
    color: black;
    text-decoration: none;
  }
}

.catalog-search-result-pagination {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 16px;
}

.catalog-search-result-card {
  margin-bottom: 16px;
  cursor: pointer;
  min-height: 100px;
  display: flex;
  flex-direction: row;
  height: 150px;

  &-container {
    width: 100%;
    height: 100%;
    padding: 0 !important;
  }

  &-wrapper {
    width: 100%;
    height: 100%;
    padding: 16px;
    padding-top: 8px !important;
  }

  &-image {
    width: 200px;
    height: 150px;
    flex: none;
  }

  &-no-image {
    width: 200px;
    height: 150px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: $color-gray-600;
    background-color: $color-gray-300;
  }

  &-content {
    width: 100%;
  }

  &-header {
    display: flex;
    flex-direction: row;

    &-name-container {
      display: flex;
      flex-direction: column;
    }

    &-name-row {
      display: flex;
      flex-direction: row;

      .MuiTypography-root {
        margin-right: 16px;
      }
    }
  }

  &-icon {
    margin-left: 8px;
    margin-right: 16px;
    margin-top: 8px;
  }

  &-name {
    margin: 0;
    font-size: 1.6rem;
  }

  &-chip-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-top: 16px;

    & .MuiChip-root {
      margin-right: 4px;
      margin-bottom: 4px;
    }
  }

  &-features {
    margin-top: 4px;

    .MuiChip-root {
      margin-right: 2px;
      margin-bottom: 2px;
    }
  }
}

@include mq(sm) {
  .catalog-search-result-card {
    flex-direction: column;
    height: 210px;
    &-container {
      height: 110px;
      overflow-y: scroll;
    }
    &-wrapper {
      padding: 8px;
    }
    &-image {
      width: 100%;
      height: 100px;
    }
    &-no-image {
      width: 100%;
    }
    &-header {
      &-name-row {
        .MuiTypography-root {
          margin-right: 8px;
        }
      }
    }
    &-name {
      font-size: 1.1rem;
    }
    &-features {
      .MuiChip-root {
        font-size: 0.7rem;
      }
    }
    .MuiTypography-caption {
      font-size: 0.7rem;
    }
  }
}
