@import 'src/variables';

.auth-provider-dialog {
  .close-dialog {
    position: absolute;
    top: 20px;
    right: 20px;

    &:hover {
      color: $primary-light;
      cursor: pointer;
    }
  }

  &-content {
    display: flex;
    flex-direction: column;

    &-provider {
      display: inline-block;
      margin-left: $space-m;
    }
  }
}
