@import '../../../variables';

.diff-frame {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding-top: $space-m;
  .spacer {
    width: 94px;
  }
  .key-orig,
  .key-dest {
    position: relative;
    .keycodekey {
      margin: 0 !important;
    }
  }

  .arrow {
    margin: 0 16px;
  }
  .cancel-button {
    margin-left: 8px;
  }
}
