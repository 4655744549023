@import '../../../_variables.scss';

.organizations-header {
  display: flex;
  position: fixed;
  height: calc(var(--key-height));
  width: 100%;
  flex-direction: row;
  align-items: center;
  margin: 0;
  padding: 0 $space-m;
  background: white;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  z-index: 2;
  justify-content: center;

  &-menu-button {
    position: fixed;
    right: 16px;
    cursor: pointer;
  }

  &-avatar {
    width: 31px !important;
    height: 31px !important;
  }
}
