@import '../../../variables';

.organization-list-wrapper {
  position: fixed;
  width: 100%;
  max-width: 960px;
  margin-top: calc(var(--key-height));
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.organization-list-container {
  width: 100%;
  height: calc(100vh - var(--key-height) - 26px);
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.organization-list {
  width: 100%;
  padding-top: 16px;
  padding-bottom: 16px;

  &-buttons {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-top: 16px;
  }
}

.organization {
  margin-bottom: 16px;
  .no-registered-organization {
    text-align: center;
    padding: 64px;
    font-size: 1rem;
  }
}

.organization-container {
  display: flex;
  flex-direction: row;
  align-items: center;

  &-left {
  }

  &-right {
    margin-left: auto;
  }
}

.organization-header {
  display: flex;
  flex-direction: row;
  align-items: center;
  .status-badge-in-review {
    background-color: $primary-pale;
  }
}

.organization-name {
  margin: 0 16px 0 0;
  font-size: 2rem;
}

.organization-meta {
  display: flex;
  flex-direction: row;

  &-info {
    margin-right: 16px;

    &-label {
      display: inline-block;
      font-weight: bold;
      margin-right: 8px;
    }
  }
}
