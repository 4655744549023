@import '../../../variables';
.catalog-search-condition-container {
  display: flex;
  flex-direction: column;
  margin: 16px 16px 48px 16px;
}

.catalog-search-condition {
  margin-bottom: 16px;
}

.catalog-search-buttons {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;

  & button {
    margin-left: 16px;
  }
}
