@import '../../../variables';

.customkey-auto-select-item {
  position: relative;
  display: flex;
  flex-direction: column;
  padding: $space-s 0;
  font-size: 0.87rem;
  width: 100%;
  .keycode-auto-label-wrapper {
    display: flex;
    flex-direction: row;
    .keycode-auto-label {
      flex-grow: 1;
    }
    .keycode-auto-category {
      color: $color-gray-600;
    }
  }
  .keycode-auto-desc {
    font-size: 0.8rem;
    color: $color-gray-600;
  }
}
