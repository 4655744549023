@import '../../../variables';

.create-definition-wrapper {
  position: fixed;
  width: 100%;
  margin-top: calc(var(--key-height));
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.create-definition-container {
  width: 100%;
  height: calc(100vh - var(--key-height) - 26px);
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.create-definition-stepper {
  margin-top: $space-l;
  margin-bottom: $space-l !important;
}

.create-definition-card {
  width: 100%;
  max-width: 960px;
  padding-top: 16px;
  padding-bottom: 16px;
}

.create-definition-form-container {
  display: flex;
  flex-direction: row;
}

.create-definition-upload-form {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  flex: none;
  width: 400px;
  height: 100%;
  max-height: 500px;
  overflow-y: auto;
}

.create-definition-upload-msg {
  font-weight: bold;
  font-size: 140%;
}

.create-definition-form {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 32px 0 32px;

  &-row {
    margin-bottom: 16px;
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  &-buttons {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    button {
      margin-left: $space-l;
    }
  }

  &-notice {
    font-size: 80%;
    color: $color-gray;
    margin-top: $space-m;
  }
}
