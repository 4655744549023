@import '../../../variables';

.catalog-keyboard {
  &-wrapper {
    position: fixed;
    width: 100%;
    max-width: 960px;
    margin-top: calc(var(--key-height));
    z-index: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &-container {
    width: 100%;
    height: calc(100vh - var(--key-height) - 26px);
    overflow-y: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &-tabs {
    width: 100%;
    margin-bottom: 16px;
  }

  &-content {
    width: 100%;
  }

  @include mq(sm) {
    &-header {
      padding: 8px;
    }
  }

  &-column {
    width: 100%;
  }

  &-image {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;

    & img {
      width: 400px;
      height: 300px;
    }

    &-nothing {
      width: 400px;
      height: 300px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
    }
  }

  &-section {
    margin: 0 8px 16px 8px;

    & h2 {
      font-size: 24px;
      font-weight: bold;
      margin-bottom: 16px;
    }
  }

  &-chip-container {
    margin-bottom: 16px;

    & div {
      margin-right: 8px;
      margin-bottom: 8px;
    }
  }

  &-stores {
    display: flex;
    flex-direction: row;
  }

  &-store {
    display: inline-block;
    margin-right: 16px;
  }
}
