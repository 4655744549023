@import '../../../../variables';

.connection-modal {
  display: flex;
  align-items: center;
  justify-content: center;
}
.paper {
  background-color: white;
  border: '2px solid #000';
  box-shadow: 5px;
  padding: $space-m;
}
