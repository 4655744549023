@import 'src/variables';

.edit-definition-catalog-form-container {
  display: flex;
  flex-direction: row;
}

.edit-definition-catalog-form {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 32px 0 32px;

  &-section {
    margin-bottom: 16px;
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  &-row {
    margin-bottom: 24px;
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  &-additional-description {
    padding: $space-m;
    margin-bottom: $space-xl;

    &-entry {
      display: flex;
      flex-direction: row;
      align-items: center;

      &-field {
        flex: auto;
        margin-right: $space-m !important;
      }
    }

    &-row {
      width: 100%;
      display: flex;
      flex-direction: column;
      margin-top: $space-m;

      &:last-child {
        margin-top: $space-l;
      }

      &-buttons {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        margin-top: $space-m;

        button {
          margin-right: $space-s;
        }
      }
    }
  }

  &-buttons {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;

    button {
      margin-left: $space-s;
    }
  }

  &-upload-image-form {
    display: flex;
    flex-direction: row;

    &:first-child {
      margin-bottom: 8px;
    }

    &-area {
      width: 200px;
      height: 150px;
      border: 1px dashed rgba(0, 0, 0, 0.2);
      border-radius: 10px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;

      &-active {
        border: 2px dashed rgba(0, 0, 0, 0.2);
        font-weight: bold;
      }
    }

    &-progress {
      width: 200px;
      height: 150px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
    }

    &-message {
      color: $color-gray-500;
    }

    &-thumbnail {
      margin-left: 8px;
      width: 200px;
      height: 150px;
      border: 1px solid $color-gray-300;
    }

    &-image {
      margin-left: 8px;
      border: 1px solid $color-gray-300;
      width: 200px;
      height: 150px;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
    }

    &-sub-image {
      margin-left: 8px;
      border: 1px solid $color-gray-300;
      width: 200px;
      height: 150px;
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;

      &-wrapper {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        background-color: rgba(255, 255, 255, 0.8);
        color: black;
        cursor: pointer;
      }
    }
  }

  &-store-form {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }
}
