:root {
  --header-height: 42px;
  --key-height: 56px;
  --key-width: 56px;
}

$font-xl: 140%;
$font-l: 100%;
$font-m: 62.5%;
$font-s: 58%;
$font-xs: 40%;

$color-gray: gray;
$color-gray-100: #f5f5f5;
$color-gray-200: #eeeeee;
$color-gray-300: #e0e0e0;
$color-gray-400: #dbdbdb;
$color-gray-500: #9e9e9e;
$color-gray-600: #757575;

$primary: #3f51b5;
$primary-light: #7986cb;
$primary-pale: #c5cae9;
$primary-thin: #e8eaf6;
$success: $primary;
$error: #f44336;
$warning: #ff9800;
$info: #8bc34a;

$background-color-gray: #f3f3f3;

$space-xl: 32px;
$space-l: 16px;
$space-m: 8px;
$space-s: 4px;
$space-xs: 2px;

// media query
$breakpoints: (
  'sm': 'screen and (max-width: 599px)',
  'md': 'screen and (max-width: 768px)',
  'lg': 'screen and (max-width: 1000px)',
  'xl': 'screen and (min-width: 1001px)',
) !default;
@mixin mq($breakpoint: md) {
  @media #{map-get($breakpoints, $breakpoint)} {
    @content;
  }
}
